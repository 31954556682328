import { getJSONArr } from '../../funder/components/grantCycles/GrantRequestForm/SupplementalQuestions/utils';
import { SurveyQuestionGetter, SurveyQuestionType } from '../enums/survey.enum';
import { ISurveyQuestion } from '../interfaces/ISurvey.interfaces';

const { SUPPLEMENTARY_QUESTIONS, QUESTION_PAYLOAD, QUESTION_DESCRIPTION } =
  SurveyQuestionGetter;

const parsePayload = (q: ISurveyQuestion) => {
  let result = null;
  const { type, payload } = q;
  try {
    const isStr = typeof payload === 'string';
    if (isStr) {
      result = payload;
    } else {
      const shouldParse = payload && type == SurveyQuestionType.MULTIPLE;
      result = shouldParse ? JSON.stringify(payload) : null;
    }
  } catch (e) {
    console.error(e);
  }
  return result;
};

const composeQuestion = (
  key: string,
  questions: any,
  current: ISurveyQuestion[] | null
) => {
  const formQ = questions[key] || ({} as ISurveyQuestion);
  const currentQ = current?.[parseInt(key)] || ({} as ISurveyQuestion);

  const payload = parsePayload(formQ);
  const type = parseInt(formQ.type || currentQ.type || SurveyQuestionType.OPEN);
  const requireQuestion = formQ.is_required || false;

  const question = {
    ...currentQ,
    ...formQ,
    requireQuestion,
    payload,
    type
  };

  return question;
};

export const composeSupplementaryQuestions = (
  formData: any,
  current: ISurveyQuestion[] | null
) => {
  let rawToUpsert = formData[SUPPLEMENTARY_QUESTIONS] || [];
  rawToUpsert = rawToUpsert.filter((q: any) => ((q.id && current?.find((c) => c.id === q.id)) || q.id == null));

  let toUpsert = Object.keys(rawToUpsert)
    .map((key) => composeQuestion(key, rawToUpsert, current))
    .filter((question: any) => question);

  if (current && current?.length > 0 && formData[SUPPLEMENTARY_QUESTIONS]) {
    current.forEach((q) => {
      if (!toUpsert.find((c) => c.id === q.id)) {
        toUpsert.push({ ...q, deleteQuestion: true });
      }
    });
  }

  return [...toUpsert];
};

export const isEmptyOrSpaces = (str: string) => {
  return !str || !str.trim();
};

const hasEmptyOptions = (options: any) => {
  let parsedOptions = [];

  if (options && typeof options === 'string') {
    parsedOptions = getJSONArr(options);
  }

  if (options && Array.isArray(options)) {
    parsedOptions = options;
  }

  return parsedOptions.some((opt: any) => isEmptyOrSpaces(opt.value));
};

export const hasEmptyQuestions = (questions: any[]) => {
  return questions?.some((q) => {
    const descriptionError = isEmptyOrSpaces(q[QUESTION_DESCRIPTION] || '');
    const optionError =
      (q.type == SurveyQuestionType.MULTIPLE ||
        q.type == SurveyQuestionType.SINGLE) &&
      hasEmptyOptions(q[QUESTION_PAYLOAD]);

    return descriptionError || optionError;
  });
};

