export enum GrantCycleType {
    OPEN = 0,
    INVITATION_ONLY = 1
}

export enum GrantCycleSections {
  GrantSetUp = 'GrantSetUp',
  GrantDates = 'GrantDates',
  GrantRequestForProposals = 'GrantRequestForProposals',
  GrantInvite = 'GrantInvite',
  GrantCommunications = 'GrantCommunications',
  GrantCheckIn = 'GrantCheckIn'
}