import { FunderServiceLevel } from '../../../../shared/enums/funder/funder-enums';
import { GrantCycleType } from '../../../../shared/enums/grant-cycle.enum';
import { IRfp } from '../../../../shared/interfaces/IRfp.interfaces';
import { composeSupplementaryQuestions } from '../../../../shared/util/questions';

const allowedServiceLevels = [
  FunderServiceLevel.Standard,
  FunderServiceLevel.Institution,
  FunderServiceLevel.FullService,
  FunderServiceLevel.Enterprise,
  FunderServiceLevel.Premium,
  FunderServiceLevel.Basic
];

export class Utils {
  public static allowedCheckInFeatures(level: FunderServiceLevel) {
    return level ? allowedServiceLevels.includes(level) : false;
  }
}

export const hasRfpInvitations = (rfp: IRfp, watchedData?: Partial<IRfp>) => {
  const invt =
    watchedData?.invitations?.length || rfp?.invitations?.length || 0;
  return invt > 0;
};

export const getRfpType = (
  rfp: IRfp,
  watchedData?: Partial<IRfp>
): GrantCycleType | null => {
  return Number(watchedData?.type ?? rfp?.type) ?? null;
};

export const shouldHandleRfpInvitations = (
  rfp: IRfp,
  watchedData?: Partial<IRfp>
) => {
  const hasInvitations = hasRfpInvitations(rfp, watchedData);
  const rfpType = getRfpType(rfp, watchedData);

  return rfpType === GrantCycleType.INVITATION_ONLY || hasInvitations;
};

export const getRequiredResources = (
  watchedData: any,
  attachments: any,
  defaultResources: any
) => {
  if (!watchedData.attachment_confirm) {
    return defaultResources;
  }
  return watchedData.attachment_confirm === 'true' ? attachments : [];
};

export const getNotificationsEnabled = (
  watchedData: any,
  defaultEnabled: boolean
): boolean => {
  if (!watchedData.notifications_enabled) {
    return defaultEnabled;
  }
  return watchedData.notifications_enabled === 'yes';
};

export const getQuestions = (watchedData: any, rfpSurveyQuestions: any) => {
  if (watchedData.questions) {
    return composeSupplementaryQuestions(watchedData, rfpSurveyQuestions);
  }
  return rfpSurveyQuestions;
};

export const getChangedField = (field: string, watchedData: any, rfp: any) => {
  return watchedData?.[field] ?? rfp?.[field];
};
