const getJSONArr = (data?: string | object) => {
  let result = [];
  if (typeof data === 'object' && Array.isArray(data)) {
    result = data;
  } else if (typeof data === 'string') {
    result = getJSONObj(data) || [];
  }
  return result;
};

const getJSONObj = (value?: string) => {
  let obj = null;
  try {
    obj = value ? JSON.parse(value) : null;
  } catch (error) {
    console.error('Error parsing JSON', error);
  }
  return obj;
};

const getIsEqualObjs = (objA: any, objB: any) => {
  if (objA && objA != null && objB && objB != null) {
    return objA === objB;
  }
  return false;
}

export { getJSONObj, getJSONArr, getIsEqualObjs };
